import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { LocalCacheService } from './localCache.service';
import { JsonDataService } from './json-data.service';
import { GlobalService } from './global.service';
import { stateList } from '../utils/stateList';
import { environment } from '../../environments/environment';
import moment from 'moment';

import {
  InitialQuoteRequest,
  QuoteRequest,
  QuoteDetail,
  Traveler,
  TravelerAddress,
  TravelerMedicalInfo,
  EmergencyContact,
} from '../interfaces/quote';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  private submissionUrl = `${environment.baseAPIUrl}quote/submission`;
  private travelUrl = `${environment.baseAPIUrl}quote/submission/create/traveler`;
  private ctmUrl = `${environment.baseAPIUrl}quote/vendor/ctm/resumeestimatedquote`;
  private finderUrl = `${environment.baseAPIUrl}quote/vendor/finder/resumeestimatedquotee`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private localCacheService: LocalCacheService,
    private jsonDataService: JsonDataService,
    private globalService: GlobalService,
  ) {}

  getInitialQuote(values: any): Observable<any> {
    this.globalService.showLoading('getInitialQuote');
    const initialQuoteRequest = this.buildInitialSubmissionRequest(values);
    initialQuoteRequest.stepName = 'Home';
    return this.http
      .put<any>(this.submissionUrl, initialQuoteRequest, this.httpOptions)
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('getInitialQuote');
        }),
        catchError(
          this.globalService.handleError<any>('initial submission', []),
        ),
      );
  }

  getQuoteByQuoteId(quoteId: string): Observable<any> {
    this.globalService.showLoading('getQuoteByQuoteId');
    return this.http
      .get<any>(`${this.submissionUrl}/${quoteId}`, this.httpOptions)
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('getQuoteByQuoteId');
          console.log('fetched quote detial by Id');
        }),
        catchError(this.globalService.handleError<any>('quote detial', [])),
      );
  }

  submitQuote(values: any, pageName: string): Observable<any> {
    this.globalService.showLoading('submitQuote');
    switch (pageName) {
      case 'home':
        const quoteRequest = this.updateQuoteDetailOnHomePage(values);
        quoteRequest.stepName = 'Home';
        console.log('quoteRequest', quoteRequest);
        return this.http
          .put<any>(this.submissionUrl, quoteRequest, this.httpOptions)
          .pipe(
            tap((_) => {
              console.log('fetched submission');
              this.globalService.hideLoading('submitQuote');
            }),
            catchError(
              this.globalService.handleError<any>('submission home page', []),
            ),
          );
        break;
      case 'step-one-product-select':
        const quoteRequestStepOne =
          this.updateQuoteDetailOnStepOneProduct(values);
        quoteRequestStepOne.stepName = 'Step1';
        console.log('quoteRequestStepOne', quoteRequestStepOne);
        return this.http
          .put<any>(this.submissionUrl, quoteRequestStepOne, this.httpOptions)
          .pipe(
            tap((_) => {
              this.globalService.hideLoading('submitQuote');
              console.log('fetched submission on step one');
            }),
            catchError(
              this.globalService.handleError<any>('submission step one', []),
            ),
          );
        break;
      case 'step-two-traveler-detail':
        const quoteRequestStepTwo =
          this.updateQuoteDetailOnStepTwoTraveler(values);
        quoteRequestStepTwo.stepName = 'Step3';
        console.log('quoteRequestStepTwo', quoteRequestStepTwo);
        return this.http
          .put<any>(this.submissionUrl, quoteRequestStepTwo, this.httpOptions)
          .pipe(
            tap((_) => {
              this.globalService.hideLoading('submitQuote');
              console.log('fetched submission on step two');
              let newDependents = [] as any;
              _.travelers?.map((traveler: any) => {
                if (traveler.role === 'dependent') {
                  newDependents.push({
                    travelerId: traveler.travelerId,
                    firstName: traveler.firstName,
                    lastName: traveler.lastName,
                    dateOfBirth: traveler.dateOfBirth,
                  });
                }
              });
              let newTravelerDetails =
                this.localCacheService.getSessionStorage('travelerDetails');

              if (newTravelerDetails) {
                newTravelerDetails.dependents = newDependents;
                this.localCacheService.saveSessionStorage(
                  'travelerDetails',
                  newTravelerDetails,
                );
              }
            }),
            catchError(
              this.globalService.handleError<any>('submission step two', []),
            ),
          );
        break;
      case 'update-traveler-detail':
        const quoteRequestNewTravelerDetails =
          this.updateQuoteDetailOnStepTwoTraveler(values);
        quoteRequestNewTravelerDetails.stepName = 'Step3ForceUpdate';
        console.log('update-traveler-detail', quoteRequestNewTravelerDetails);
        return this.http
          .put<any>(
            this.submissionUrl,
            quoteRequestNewTravelerDetails,
            this.httpOptions,
          )
          .pipe(
            tap((_) => {
              this.globalService.hideLoading('submitQuote');
              console.log('fetched submission on update-traveler-detail');
              let newDependents = [] as any;
              _.travelers?.map((traveler: any) => {
                if (traveler.role === 'dependent') {
                  newDependents.push({
                    travelerId: traveler.travelerId,
                    firstName: traveler.firstName,
                    lastName: traveler.lastName,
                    dateOfBirth: traveler.dateOfBirth,
                  });
                }
              });
              let newTravelerDetails =
                this.localCacheService.getSessionStorage('travelerDetails');
              if (newTravelerDetails) {
                newTravelerDetails.dependents = newDependents;
                this.localCacheService.saveSessionStorage(
                  'travelerDetails',
                  newTravelerDetails,
                );
              }
            }),
            catchError(
              this.globalService.handleError<any>(
                'submission update-traveler-detail',
                [],
              ),
            ),
          );
      case 'update-traveler-address':
        const newRequest = this.updateQuoteDetailOnStepTwoTraveler(values);
        newRequest.stepName = 'Step2';
        console.log('update-traveler-adress', newRequest);
        return this.http
          .put<any>(this.submissionUrl, newRequest, this.httpOptions)
          .pipe(
            tap((_) => {
              this.globalService.hideLoading('submitQuote');
              console.log('fetched submission on update-traveler-address');
            }),
            catchError(
              this.globalService.handleError<any>(
                'submission update-traveler-address',
                [],
              ),
            ),
          );
      case 'send-email':
        let sendEmailRequest = this.updateQuoteDetailOnStepTwoTraveler(values);
        sendEmailRequest.stepName = 'Step1';
        return this.http
          .put<any>(this.submissionUrl, sendEmailRequest, this.httpOptions)
          .pipe(
            tap((_) => {
              this.globalService.hideLoading('submitQuote');
              console.log('fetched submission before sending email');
            }),
            catchError(this.globalService.handleError<any>('send-email', [])),
          );
      case 'step-three-marketing-flag':
        const quoteRequestStepThree = this.updateQuoteDetailOnStepThree(values);
        quoteRequestStepThree.stepName = 'Step3';
        console.log('quoteRequestStepThree', quoteRequestStepThree);
        return this.http
          .put<any>(this.submissionUrl, quoteRequestStepThree, this.httpOptions)
          .pipe(
            tap((_) => {
              this.globalService.hideLoading('submitQuote');
              console.log('fetched submission on step three');
            }),
            catchError(
              this.globalService.handleError<any>('submission step three', []),
            ),
          );
      default:
        return of({});
    }
  }

  private buildBasicSubmissionRequest(
    values: any,
    isInitial: boolean = false,
  ): any {
    let quoteRequestBasic = {} as QuoteRequest;
    if (!isInitial) {
      var quoteDetails =
        this.localCacheService.getSessionStorage('quoteDetail');
      quoteRequestBasic.quoteId = quoteDetails.quoteId ?? '';
      const productDetail = this.localCacheService.getSessionStorage(
        'selectedCoverDetail',
      );
      if (quoteDetails.isSingleTrip !== values.isSingleTrip) {
        quoteRequestBasic.clubProductGuid =
          '00000000-0000-0000-0000-000000000000';
      } else {
        let newDomesticCountry = values.travelDestination.every(
          (country: any) => country.isDomestic === true,
        );
        let oldDomesticCountry =
          this.localCacheService.getSessionStorage('domesticCountry') || false;
        
        let _isCruiseCountry = values.travelDestination.find(
          (country: any) => country.countryCode === 'AUC',
        );
        let isCruiseCountry = _isCruiseCountry ? true : false;
        let oldIsCruiseCountry =
          this.localCacheService.getSessionStorage('isCruiseCountry') || false;
        if ((newDomesticCountry !== oldDomesticCountry) || (isCruiseCountry !== oldIsCruiseCountry)) {
          quoteRequestBasic.clubProductGuid =
            '00000000-0000-0000-0000-000000000000';
        } else {
          quoteRequestBasic.clubProductGuid =
            productDetail.selectedPrice.clubProductGuid;
        }
      }
    }
    quoteRequestBasic.pricingDate = moment(new Date()).format('DD/MM/YYYY');
    quoteRequestBasic.fromDate = moment(values.depart).format('DD/MM/YYYY');
    quoteRequestBasic.toDate = moment(values.return).format('DD/MM/YYYY');
    if (values.age1) {
      quoteRequestBasic.ages = [values.age1];
    }
    if (values.age2 && values.age2 > 0) {
      quoteRequestBasic.ages.push(values.age2);
    }
    quoteRequestBasic.destinations = this.getCountryObject(
      values.travelDestination,
    );
    quoteRequestBasic.dependentsCount = Number(values.dependentsCount);
    quoteRequestBasic.isSingleTrip = values.isSingleTrip;
    quoteRequestBasic.stateId = values.state.id;
    quoteRequestBasic.promoCode = values.promoCode;
    quoteRequestBasic.autoClub = environment.autoClub;
    quoteRequestBasic.isClubMember =
      typeof values.isClubMember === 'boolean'
        ? values.isClubMember
        : values.isClubMember === 'true';
    quoteRequestBasic.channel = 'Online';
    quoteRequestBasic.acknowledgementFlag = true;
    quoteRequestBasic.membershipNumber = quoteDetails?.membershipNumber ?? '';
    quoteRequestBasic.marketingConsent = values.marketingConsent ?? false;
    quoteRequestBasic.travelers = [];
    const agentGuid = this.localCacheService.getSessionStorage('agentGuid');
    const branchGuid = this.localCacheService.getSessionStorage('branchGuid');
    if (agentGuid) {
      quoteRequestBasic.agentInfo = {
        agentGuid: agentGuid,
        branchGuid: branchGuid,
      };
    }
    return quoteRequestBasic;
  }

  private buildTravelers(quoteEnquiry: any): Array<Traveler> {
    let Travelers = [] as Array<Traveler>;
    const travelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails');
    if (!travelerDetails) return Travelers;
    const assessmentDetails =
      this.localCacheService.getSessionStorage('assessmentDetails') ?? {};
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    if (
      travelerDetails.adult1?.firstName &&
      travelerDetails?.adult1?.lastName &&
      travelerDetails?.adult1?.dateOfBirth
    ) {
      let primaryTraveler = {} as Traveler;
      primaryTraveler.firstName = travelerDetails?.adult1?.firstName ?? '';
      primaryTraveler.lastName = travelerDetails?.adult1?.lastName ?? '';
      primaryTraveler.role = 'primary';
      primaryTraveler.dateOfBirth = travelerDetails?.adult1?.dateOfBirth;
      primaryTraveler.email =
        travelerDetails?.contactAndAddressDetails?.emailId ?? '';
      primaryTraveler.phone =
        travelerDetails?.contactAndAddressDetails?.phoneNumber ?? '';

      let address = {} as TravelerAddress;
      address.countryCode = 'AUS';
      let currentState = stateList.find(
        (state) =>
          state.code === travelerDetails?.contactAndAddressDetails?.state,
      );

      // reset address and use the stateid from quote enquiry
      if (currentState && currentState.id !== quoteEnquiry.state?.id) {
        address.stateId = quoteEnquiry.state?.id;
        if (travelerDetails && travelerDetails.contactAndAddressDetails) {
          travelerDetails.contactAndAddressDetails.postalCode = '';
          travelerDetails.contactAndAddressDetails.street = '';
          travelerDetails.contactAndAddressDetails.subUrb = '';
          travelerDetails.contactAndAddressDetails.state =
            quoteEnquiry?.state?.code;
          this.localCacheService.saveSessionStorage(
            'travelerDetails',
            travelerDetails,
          );
        }
      } else {
        address.stateId = currentState ? currentState.id : quoteDetail.stateId;
      }
      address.postalCode =
        travelerDetails?.contactAndAddressDetails?.postalCode ?? '';
      address.address =
        travelerDetails?.contactAndAddressDetails?.street ??
        '' + ' ' + travelerDetails?.contactAndAddressDetails?.subUrb ??
        '';
      address.city = travelerDetails?.contactAndAddressDetails?.subUrb ?? '';
      primaryTraveler.address = address;
      primaryTraveler.travelerId = assessmentDetails?.primary?.travelerId;
      const primaryTravelerInfo = quoteDetail?.travelers?.find((trv: any) => trv.role === 'primary');
      if (
        primaryTravelerInfo && primaryTravelerInfo?.travelerId
      ) {
        primaryTraveler.travelerId = primaryTravelerInfo?.travelerId;
      }
      if (
        travelerDetails?.adult1?.one_med_con == 'no' ||
        travelerDetails.medical_con === 'no'
      ) {
        primaryTraveler.medicalRequired = false;
      }
      Travelers.push(primaryTraveler);
    }
    if (
      quoteEnquiry.age2 !== undefined &&
      quoteEnquiry.age2 !== null &&
      quoteEnquiry.age2 !== ''
    ) {
      if (
        travelerDetails.adult2?.firstName &&
        travelerDetails?.adult2?.lastName &&
        travelerDetails?.adult2?.dateOfBirth
      ) {
        let secondaryTraveler = {} as Traveler;
        secondaryTraveler.firstName = travelerDetails?.adult2?.firstName ?? '';
        secondaryTraveler.lastName = travelerDetails?.adult2?.lastName ?? '';
        secondaryTraveler.role = 'secondary';
        secondaryTraveler.dateOfBirth = travelerDetails?.adult2?.dateOfBirth;
        secondaryTraveler.travelerId = assessmentDetails?.secondary?.travelerId;
        const secondaryTravelerInfo = quoteDetail?.travelers?.find((trv: any) => trv.role === 'secondary');
        if (
          secondaryTravelerInfo && secondaryTravelerInfo?.travelerId
        ) {
          secondaryTraveler.travelerId = secondaryTravelerInfo?.travelerId;
        }
        if (
          travelerDetails?.adult2?.two_med_con == 'no' ||
          travelerDetails.medical_con === 'no'
        ) {
          secondaryTraveler.medicalRequired = false;
        }
        Travelers.push(secondaryTraveler);
      }
    }
    if (travelerDetails.dependents && travelerDetails.dependents.length > 0) {
      travelerDetails.dependents.forEach((dependent: any) => {
        if (
          dependent.firstName &&
          dependent.lastName &&
          dependent.dateOfBirth
        ) {
          let dependentTraveler = {} as Traveler;
          dependentTraveler.firstName = dependent.firstName;
          dependentTraveler.lastName = dependent.lastName;
          dependentTraveler.role = 'dependent';
          dependentTraveler.dateOfBirth = dependent.dateOfBirth;
          if (
            dependent.travelerId !== undefined &&
            dependent.travelerId !== null &&
            dependent.travelerId !== ''
          ) {
            dependentTraveler.travelerId = dependent.travelerId;
          }
          if (
            dependent?.dependent_med_con == 'no' ||
            travelerDetails.medical_con === 'no'
          ) {
            dependentTraveler.medicalRequired = false;
          }
          Travelers.push(dependentTraveler);
        }
      });
    }
    return Travelers;
  }

  private buildEmergencyContact(): EmergencyContact | null {
    const travelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails');
    if (!travelerDetails || !travelerDetails.emergencyContact) return null;
    let emergencyContact = {} as EmergencyContact;
    emergencyContact.firstName =
      travelerDetails?.emergencyContact?.firstName ?? '';
    emergencyContact.lastName =
      travelerDetails?.emergencyContact?.lastName ?? '';
    emergencyContact.email = travelerDetails?.emergencyContact?.emailId ?? '';
    emergencyContact.phone =
      travelerDetails?.emergencyContact?.phoneNumber ?? '';

    return emergencyContact;
  }

  private buildInitialSubmissionRequest(values: any): InitialQuoteRequest {
    let quoteRequest = this.buildBasicSubmissionRequest(values, true);
    quoteRequest.clubProductGuid = '00000000-0000-0000-0000-000000000000';
    return quoteRequest;
  }

  private updateQuoteDetailOnHomePage(values: any): QuoteDetail {
    let quoteRequest = this.buildBasicSubmissionRequest(values);
    quoteRequest.travelers = this.buildTravelers(values);
    const emergencyContact = this.buildEmergencyContact();
    if (emergencyContact) {
      quoteRequest.emergencyContact = emergencyContact;
    }
    return quoteRequest;
  }

  private updateQuoteDetailOnStepOneProduct(values: any): QuoteDetail {
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    let quoteRequest = this.buildBasicSubmissionRequest(quoteEnquiry);
    quoteRequest.travelers = this.buildTravelers(quoteEnquiry);
    const emergencyContact = this.buildEmergencyContact();
    if (emergencyContact) {
      quoteRequest.emergencyContact = emergencyContact;
    }
    const productDetail = this.localCacheService.getSessionStorage(
      'selectedCoverDetail',
    );

    quoteRequest.clubProductGuid = productDetail.selectedPrice.clubProductGuid;
    if (productDetail.selectedPrice?.cruiseDates) {
      quoteRequest['cruiseDates'] = productDetail.selectedPrice?.cruiseDates;
    }
    if (productDetail.selectedPrice?.skiDates) {
      quoteRequest['skiDates'] = productDetail.selectedPrice?.skiDates;
    }
    return quoteRequest;
  }

  private updateQuoteDetailOnStepTwoTraveler(values: any): QuoteDetail {
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    let quoteRequest = this.buildBasicSubmissionRequest(quoteEnquiry);
    const travelers = this.buildTravelers(quoteEnquiry);
    quoteRequest.travelers = travelers;
    const emergencyContact = this.buildEmergencyContact();
    if (emergencyContact) {
      quoteRequest.emergencyContact = emergencyContact;
    }
    if (quoteEnquiry.isClubMember) {
      quoteRequest.membershipNumber = values?.adult1?.membershipNumber ?? quoteRequest.membershipNumber ?? '';
    }
    return quoteRequest;
  }

  private updateQuoteDetailOnStepThree(values: any): QuoteDetail {
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    quoteEnquiry.marketingConsent = values.marketing;
    let quoteRequest = this.buildBasicSubmissionRequest(quoteEnquiry);
    const travelers = this.buildTravelers(quoteEnquiry);
    quoteRequest.travelers = travelers;
    const emergencyContact = this.buildEmergencyContact();
    if (emergencyContact) {
      quoteRequest.emergencyContact = emergencyContact;
    }
    return quoteRequest;
  }

  approveQuote(quoteId: string): Observable<any> {
    this.globalService.showLoading('approveQuote');
    return this.http
      .put<any>(
        `${this.submissionUrl}/approve/${quoteId}`,
        null,
        this.httpOptions,
      )
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('approveQuote');
          console.log('fetched submission/approve');
        }),
        catchError(
          this.globalService.handleError<any>(
            'submission/approve step-3 traveler details page',
            [],
          ),
        ),
      );
  }
  getSubmission(quoteId: string): Observable<any> {
    this.globalService.showLoading('getSubmission');
    return this.http.get<any>(`${this.submissionUrl}/${quoteId}`).pipe(
      tap((_) => {
        this.globalService.hideLoading('getSubmission');
        console.log('fetched submission/{id}');
      }),
      catchError(
        this.globalService.handleError<any>(
          'submission/fetach submission step 1 details page',
          [],
        ),
      ),
    );
  }

  getCTMSubmission(ctmToken: string): Observable<any> {
    this.globalService.showLoading('getCTMSubmission');

    return this.http
      .post<any>(`${this.ctmUrl}`, { tokenNumber: ctmToken }, this.httpOptions)
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('CTMSubmission');
          console.log('CTMSubmission');
        }),
        catchError(
          this.globalService.handleError<any>(
            'failed to get CTM submission',
            [],
          ),
        ),
      );
  }

  getFinderSubmission(finderToken: string): Observable<any> {
    this.globalService.showLoading('getFinderSubmission');

    return this.http
      .post<any>(
        `${this.finderUrl}`,
        { tokenNumber: finderToken },
        this.httpOptions,
      )
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('getFinderSubmission');
          console.log('getFinderSubmission');
        }),
        catchError(
          this.globalService.handleError<any>(
            'failed to get Finder submission',
            [],
          ),
        ),
      );
  }

  getCountryObject(countryList: any) {
    const countryArray: any[] = [];
    countryList.forEach((element: any) => {
      countryArray.push({
        countryCode: element.countryCode,
        countryName: element.countryName,
        ratingRegionName: element.ratingRegionName,
      });
    });
    return countryArray;
  }

  createTraveler(
    parameters: any,
    role: string,
    dependentIndex: number = -1,
  ): Observable<any> {
    this.globalService.showLoading('createTraveler');
    let travelerDetails = {} as any;

    travelerDetails = this.fetchTravelerBasedOnRole(
      parameters,
      role,
      dependentIndex,
    );

    console.log('travelerDetails', travelerDetails);
    return this.http
      .post<any>(`${this.travelUrl}`, travelerDetails, this.httpOptions)
      .pipe(
        tap((_) => {
          this.globalService.hideLoading('createTraveler');
          console.log('create traveler');
        }),
        catchError(
          this.globalService.handleError<any>('failed to create traveler', []),
        ),
      );
  }

  fetchTravelerBasedOnRole(
    formcontrol: any,
    role: string,
    dependentIndex: any,
  ) {
    var quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    let travelerDetails = {} as any;
    travelerDetails = {
      quoteId: quoteDetail?.quoteId ?? '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      membershipNumber:'',
      role: role,
    };
    if (role === 'primary') {
      travelerDetails.firstName = formcontrol?.adult1?.firstName ?? '';
      travelerDetails.lastName = formcontrol?.adult1?.lastName ?? '';
      travelerDetails.dateOfBirth = formcontrol?.adult1?.dateOfBirth;
      travelerDetails.membershipNumber = formcontrol?.adult1?.membershipNumber ?? '';
      const primaryTravelerInfo = quoteDetail?.travelers?.find((trv: any) => trv.role === 'primary');
      if (
        primaryTravelerInfo && primaryTravelerInfo?.travelerId
      ) {
        travelerDetails.travelerId = primaryTravelerInfo?.travelerId;
      }
    } else if (role === 'secondary') {
      travelerDetails.firstName = formcontrol?.adult2?.firstName ?? '';
      travelerDetails.lastName = formcontrol?.adult2?.lastName ?? '';
      travelerDetails.dateOfBirth = formcontrol?.adult2?.dateOfBirth;
      const secondaryTravelerInfo = quoteDetail?.travelers?.find((trv: any) => trv.role === 'secondary');
      if (
        secondaryTravelerInfo && secondaryTravelerInfo?.travelerId
      ) {
        travelerDetails.travelerId = secondaryTravelerInfo?.travelerId;
      }
    } else {
      travelerDetails.firstName =
        formcontrol?.dependents[dependentIndex]?.firstName ?? '';
      travelerDetails.lastName =
        formcontrol?.dependents[dependentIndex]?.lastName ?? '';
      travelerDetails.dateOfBirth =
        formcontrol?.dependents[dependentIndex]?.dateOfBirth;
      if (
        this.localCacheService
          .getSessionStorage('quoteDetail')
          ?.travelers.find((dep: any) => dep.role == 'dependent')
      ) {
        var dependent = this.localCacheService
          .getSessionStorage('quoteDetail')
          ?.travelers.find(
            (dep: any) =>
              dep.firstName == travelerDetails.firstName &&
              dep.lastName == travelerDetails.lastName &&
              dep.dateOfBirth == travelerDetails.dateOfBirth,
          );

        if (dependent) {
          travelerDetails.travelerId = dependent.travelerId;
        }
      }
    }
    return travelerDetails;
  }
}
